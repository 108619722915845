import React from "react";
import { LinkedIn, Email, Twitter, FaceBook } from "../icons/brandlinks";

const HomeHero = (props) => {
  // let dev = "{ALM}";
  return (
    <div className="view  full-hero-intro  lighten-5 pb-3 h-100 ">
      <div className=" d-flex justify-content-center align-items-center">
        <div className="">
          <div className=" d-flex h-100 justify-content-center align-items-center wow fadeIn">
            <div className="hero-container mb-4 black-text hero-text">
              <div className="mt-4  animated fadeIn">
                <h1 className="display-5  font-weight-bold my-font animated fadeInDown">
                  <u className="grey-text">
                    <span className="text-dark">Amo</span>
                  </u>
                  helang Lawrence{" "}
                  <u className="grey-text">
                    <span className="text-dark">Mad</span>
                  </u>
                  iba
                </h1>

                <div className=" p-2 mb-3 hero-profile-text">
                  <strong className="font-weight-bold  my-font">
                    Hi I'm Full-Stack Software Developer and a startup founder
                  </strong>
                  <p className="mb-4    my-font text-justify">
                    I love programming and solving real world problems that we,
                    people encounter in our daily life. So if you have any Idea
                    or you want a good Developer, or a problem solver, just send
                    me a message, I'll get back to you ASAP. I can't wait to
                    work and help you to achieve your goals. want to know more
                    about me?
                    <a
                      href="#who-is-amo"
                      className="text-info font-italic  small"
                    >
                      {" "}
                      Read more...
                    </a>
                    <i className="far fa-linkedin-in    "></i>
                  </p>
                </div>

                <div className=" rounded hero-contact animated fadeInUp">
                  <span className="hero-contact-text">Meet me on: &nbsp; </span>
                  <LinkedIn />
                  <FaceBook />
                  <Twitter />
                  <Email />
                </div>
              </div>

              {/*  The hero img */}
              <div className="  view overlay zoom hero-img-cont rounded shadow animated fadeIn delay-1s">
                <img src="/alm.jpg" className="img-fluid hero-img " alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomeHero;
