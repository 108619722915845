import React, { Component } from "react";

class Footer extends Component {

  render() {
    let date = new Date();
    let year = date.getFullYear();        
        return (
          <footer className="border grey lighten-1  index footer d-flex flex-column justify-content-center ">
            <div className="footer-copyright text-center small ">
              &copy; {year} Copyright: &nbsp;
              <a href="https://effectivewing.com/" className="text-dark">
                AmoMad inc
              </a>
              &nbsp; All rights reserved. &nbsp;
              <span className="">
                <a href="/terms_and_conditions" className="text-dark">
                  Terms & Conditions
                </a>     
              </span>
            </div>
          </footer>
        );
    }
}

export default Footer;
