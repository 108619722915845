import React, { Component } from "react";

class AboutMe extends Component {
  render() {
    return (
      <main className="aboutme-container my-5" id="who-is-amo">
        <div className=" text-center">
          <h4 className="font-weight-bold">
            Who is <span className="">Lawrence Amo</span>
          </h4>
        </div>
        <hr />
        <br />
        <div className="aboutme-container-content mx-0  p-3 shadow grey lighten-5 rounded">
          <div className=" text-justify animated fadeInLeft">
            <p className="font-weight-bold display-5 text-center">
              <span>A little about my career</span>
            </p>
            I am Amohelang Lawrence Madiba a Professional Full-Stack Software
            Developer, I'm self-motivated, ambitious and responsible to any task
            that I undertake. I have a comprehensive skills in web design and
            development, web security, all {""}
            <b>Software Development Life Circle (SDLC)</b> phases.
            <br />I also have extensive knowledge in {""}
            <b>Software Development Methodologies</b> like
            <b> (Agile, Lean, waterfall and Scrum Development Methodology)</b>. Working
            with a team it's what I like most, because they say &nbsp; " Two
            heads are better than one". But i also like to work alone independently.
            <br /> <br />
            <p className="">
              After graduating from my high school, doing{" "}
              <span className="font-weight-bold">
                Maths and Science at Tholang SSS
              </span>
              . I went to university to study{" "}
              <span className="font-weight-bold">
                Information and Communication Technology in Software Development
              </span>
              , and I optained my Certificate after completing my studies at{" "}
              <span className="font-weight-bold">
                Durban University of Technology (DUT)
              </span>{" "}
              <br />
              <br />
              There are some short courses that I took at{" "}
              <span className="font-weight-bold">
                SISCO Network Academy (NedAcad)
              </span>{" "}
              like <span className="font-weight-bold">Network Essentials</span>{" "}
              and{" "}
              <span className="font-weight-bold">
                Introduction to Internet of Things (IoT).
              </span>{" "}
              And there are also some others that I enrolled to, from different
              platforms like{" "}
              <span className="font-weight-bold">Show Academy, Udemy, EDX</span>{" "}
              etc. These short courses improved my skills and proffesionality.
              With those skills that I gained from School, University, in these
              short courses and in projects that I developed or participated in,
              I gained more confident and I promise that I can deliver best
              quality softwares.
            </p>
            Through out my career I gained experience in these Technologies:
            <br />
            <u className="mt-3">back-end:</u>
            <b className="font-weight-bold">
              &nbsp;Python, Django Framework, PHP, Laravel Framework, ASP.NET ,
              Node js / Expess
            </b>
            <br />
            <br />
            <u className="mt-3">Frond-End:</u>
            <b className="font-weight-bold">
              &nbsp;HTML5 and CSS, JavaScript, Bootstrap Framework, React js
            </b>
            <br />
            <br />
            <u className="mt-3">Other Skills:</u>
            <b className="font-weight-bold">
              &nbsp;Git Version Control, Rest API, MySQL
            </b>
            <br />
            <hr />
          </div>
          <div className=" text-justify animated fadeInRight">
            <p className="font-weight-bold display-5 text-center">
              <span>How I got into Software Development?</span>
            </p>
            While I was in High School, my father bought me a computer, it was
            my first time owning such powerful machine. We were taking computer
            lessons at school but they were not that effective. At first I was
            using it for school work and watching movies, music, I wish it was
            that powerful that I can play games or run powerful softwares, it
            was a 32 bit, 2GB memory and 300GB HDD, and it was using Windows XP.
            I loved it because I know how he bought it. Later I started using it
            effectively, I started sending and downloading more movies, songs,
            and music videos. If there was someone who need music, movies, they
            knew that they have to spend some coins to get them. Sometimes I
            would fix someone's phone or computer, that's how I was making money
            then.
            <br />
            <br />
            My friends and nabhours started saying that I'm good with Tech, I
            didn't believe or think so, but what I know is that I was making
            money from that, and I loved what I was doing. Because of that love
            I have for Tech, I went to University after completing my
            matric(High School), and I had two options, (Information Technology
            or Cevil Engineering). I liked Cevil Eng because of my father, I
            knew that I'll get job easy if I choose it, unfortunately he passed
            away two years back, while I was doing my 11th grade. So for me to
            choose Cevil it was not a good idea, then I chose IT.
            <br />
            <br />
            Programming was new to me, remember I was using a 32bit Windows XP
            with 2GB memory, so it was difficult for me to run softwares like
            Visual Studio, I bought new laptop after my first semester of my
            first year.
            <br />
            <br />I now feel more confident in software development, after
            developing and participating in some big projects. And I still learn
            new things and trying to improve my skills everyday. I'm more open
            to work or help anyone who have ideas that can solve or bring
            positive impact. Don't ever hesitate to
            <a href="#contactme">contact me</a>.
            <hr />
          </div>
        </div>
      </main>
    );
  }
}

export default AboutMe;
