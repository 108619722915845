import React, { Component } from "react";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      email: "",
      loading: "",
      authenticated: false,
      authmsg: "",
      msg: "",
    };
    // register my methods/functions
    this.validation = this.validation.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.inputChange = this.inputChange.bind(this);
  }

  // handle the the input fields // get the values
  inputChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  // Fun that used to validate the input field from user form
  validation() {
    let name = "There is a field that ";
    if (this.state.email === "" || this.state.password === "") {
      this.setState((prevState) => {
        return {
          msg:
            prevState.msg +
            "[ " +
            name +
            " is required]... please fill all fields!!!\n",
        }; //return this msg if input is empty
      });
    } else {
      this.setState({ msg: "" }); // set the error messages to empty to show correct validation
    }
  }

  // Try to log the user in
  async submitForm(event) {
    //handle the form validation
    this.validation();
    if (this.state.msg === "") {
      this.setState({ loading: "Loading...", authmsg: "" });
      // append the data to post
      const bodyData = {
        email: this.state.email,
        password: this.state.password,
      };
      const response = await fetch(process.env.REACT_APP_URL + "login", {
        method: "POST",
        headers: { "content-type": "application/json; charset=UTF-8" },
        body: JSON.stringify(bodyData),
      });
      this.setState({ loading: "" });
      const data = await response.json();
      if (response.status === 200) {
        this.setState({
          authenticated: true,
          authmsg: "you are successfully Authenticated",
        });
        this.props.auth(data);
      } else if (response.status === 401) {
        this.setState({
          authenticated: false,
          authmsg: data,
        });
      }
    }
  }

  render() {
    return (
      <main>
        <div className=" mt-5 d-flex justify-content-center ">
          <div className="card m-5 w-50 ">
            <div className="card-body mt-0  d-flex justify-content-between">
              <div className="  border-right w-50 px-3">
                <div className="card-title h5  text-center pr-3">
                  Your data is keeped safe
                </div>
                <div>
                  <div className="border p-5 rounded">img</div>
                  <p className="small pt-2">
                    The site could be temporarily unavailable or too busy. Try
                    again in a few moments. If you are unable to load any pages,
                    check your  
                  </p>
                  <div className="border grey p-2 rounded">
                      <i class="fas fa-twitter    "></i>
                  </div>
                </div>
              </div>

              <div className="border-left w-50 px-3 ">
                <h4 className="card-title">Log in to your Account</h4>
                <div className="card-text ">
                  <div className="msgDisplay text-success">
                    {this.state.authmsg}
                    {this.state.loading}
                  </div>
                  <div className="msgDisplay text-danger">{this.state.msg}</div>
                  <form method="" className="form-group mt-3">
                    <div className="form-group">
                      <label htmlFor="">E-mail</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        onChange={this.inputChange}
                        id="email"
                        value={this.state.email}
                        aria-describedby="helpId"
                        placeholder=""
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        onChange={this.inputChange}
                        id="password"
                        value={this.state.password}
                        aria-describedby="helpId"
                        placeholder=""
                      />
                    </div>

                    <button
                      type="button"
                      className="btn btn-sm rounded w-100  font-weight-bold"
                      onClick={this.submitForm}
                    >
                      Log In
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Login;
