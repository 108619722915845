import React from "react";
import { Email, LinkedIn, Twitter, FaceBook } from "../icons/brandlinks";

const ContactMe = (props) => {
  return (
    <main className=" my-5  ">
      <div className=" text-center">
        <h4 className="font-weight-bold">Connect with Me</h4>
      </div>
      <hr />
      <br />
      <div className="p-3 shadow grey lighten-4 rounded " id="contactme">
        <div className=" d-flex justify-content-center animated fadeIn delay-1s ">
          <LinkedIn />
          <FaceBook />
          <Twitter />
          <Email />
        </div>
      </div>
    </main>
  );
};

export default ContactMe;
