import React, { Component } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
class Dashboard extends Component {
  // props.auth
  render() {
    let msg = "";
    this.props.auth ? (msg = "loged in") : (msg = "loged out");
    return (
      <main className="border grey lighten-4 mt-5 pt-5 ">
        <div>
          <Link to="/admin/profile">Profile</Link>
        </div>
        <h1 className="text-success mt-5"> This is your DashBoard [{msg}]</h1>
      </main>
    );
  }
}

export default Dashboard;
