import React, { Component } from "react";
import HomeHero from "./hero";
import AboutMe from "./aboutme";
import ProjectsContainer from "./projects-container";
import ContactMe from "./contactme";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.auth,
    };
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }
  logout() {
    this.props.authenticate(false);
  }
  login() {
    this.props.authenticate(true);
  }
  hr_main() {
    setInterval(() => {
      let hr_main = document.querySelector(".hr_main");
      if (hr_main.classList.contains("bg-info")) {
        hr_main.classList.remove("bg-info");
      } else {
        hr_main.classList.add("bg-info");
      }
    }, 5000);
  }

  render() {
    return (
      <main className=" page-container" onMouseMove={this.hr_main}>
        <HomeHero />
        <hr className="border rounded hr_main" />
        <AboutMe />
        <hr className="border bg-dark rounded" id="projects" />
        <ProjectsContainer />
        <hr className="border bg-dark rounded" />
        <ContactMe />
        <hr className="border bg-dark rounded" />
      </main>
    );
  }
}

export default Index;
