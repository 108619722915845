import React, { Component } from "react";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      loading: "",
      authmsg: "",
    };
    // register my methods/functions
    this.validation = this.validation.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.inputChange = this.inputChange.bind(this);
  }

  // handle the the input fields // get the values
  inputChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  // Fun that used to validate the input field from user form
  validation() {
    let name = "There is a field that ";
    if (this.state.email === "" || this.state.password === "") {
      this.setState((prevState) => {
        return {
          msg:
            prevState.msg +
            "[ " +
            name +
            " is required]... please fill all fields!!!\n",
        }; //return this msg if input is empty
      });
    } else {
      this.setState({ msg: "" }); // set the error messages to empty to show correct validation
    }
  }

  // Try to log the user in
  async submitForm(event) {
    //handle the form validation
    this.validation();
    if (this.state.msg === "") {
      this.setState({ loading: "Loading...", authmsg: "" });
      // append the data to post
      const bodyData = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        confirm_password: this.state.confirm_password,
        password: this.state.password,
      };
      const response = await fetch(process.env.REACT_APP_URL + "register", {
        method: "POST",
        headers: { "content-type": "application/json; charset=UTF-8" },
        body: JSON.stringify(bodyData),
      });
      this.setState({ loading: "" });
      const data = await response.json();
      if (response.status === 201) {
        this.setState({
          authmsg: "you are successfully Registered",
        });
        this.props.auth(data);
      } else if (response.status === 401) {
        this.setState({
          authmsg: data,
        });
      }
    }
  }

  render() {
    return (
      <main className="mt-5">
        <div className=" mt-5 d-flex justify-content-center">
          <div className="card  ">
            <div className="card-body ">
              <h4 className="card-title">Register New Account</h4>
              <div className="card-text ">
                <div className="msgDisplay text-success">
                  {this.state.authmsg}
                  {this.state.loading}
                </div>
                <div className="msgDisplay text-danger">{this.state.msg}</div>
                <form method="" className="form-group mt-3">
                  <div className="form-group">
                    <label htmlFor="">First Name(s)</label>
                    <input
                      type="text"
                      className="form-control"
                      name="first_name"
                      onChange={this.inputChange}
                      value={this.state.first_name}
                      placeholder="Enter Your First Name"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="last_name"
                      onChange={this.inputChange}
                      id="email"
                      value={this.state.last_name}
                      placeholder="Enter Your Last Name"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">E-mail</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      onChange={this.inputChange}
                      id="email"
                      value={this.state.email}
                      aria-describedby="helpId"
                      placeholder="Enter Your Email"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      onChange={this.inputChange}
                      value={this.state.password}
                      placeholder="Enter Your New Password"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="confirm_password"
                      onChange={this.inputChange}
                      value={this.state.confirm_password}
                      placeholder="Confirm Password"
                    />
                  </div>

                  <button
                    type="button"
                    className="btn btn-sm rounded font-weight-bold"
                    onClick={this.submitForm}
                  >
                    Register Account
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Register;
