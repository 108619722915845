import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { BrowserRouter as Route } from "react-router-dom";

const ProtectedRoute = (props) => {
  return props.auth ? (
    <Route {...props} />
  ) : (
    <Redirect to={{ pathname: "/login" }}></Redirect>
  );
};

const AuthRoute = (props) => {
  return !props.auth ? (
    <Route {...props} />
  ) : (
    <Redirect to={{ pathname: "/" }}></Redirect>
  );
};

export  {ProtectedRoute, AuthRoute}
