// import { Button } from "react-bootstrap";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaEye } from "react-icons/fa";

const Project = (props) => {
  let { name, img, details } = props;
  const [show, setShow] = useState(false);
  const modalClose = () => setShow(false);
//  const modalShow = () => {
   // return setShow(true);
 // };
   let modalShows = null; // to hide the modal. remove after development
  return (
    <>
      <main
        onClick={modalShows}
        className="shadow project-container border  my-3 rounded grey d-flex flex-column lighten-5 animated fadeInUp"
      >
        <div className="zoom border-info grey rounded-top">
          <img
            src={"/assets/projects/" + img}
            className="img-fluid  rounded"
            alt=""
          />
        </div>
        <div className="p-2">
          <div className="">
            name: <b className="font-weight-bold">{name}</b>
          </div>
          <div className=" d-flex">
            Tech:
            <span className="small">
              {props.tech.map((i) => (
                <span className="px-1" key={i}>
                  <i>{i},</i>
                </span>
              ))}
            </span>
            <div className="m-0 py-0 pl-1 border-left ml-auto c-pointer d-flex flex-column justify-content-center">
              <FaEye />
            </div>
          </div>
        </div>
      </main>
      {/*  */}
      <Modal show={show} onHide={modalClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-justify">
          <div className="">
            <img
              src={"/assets/projects/" + img}
              className="img-fluid project-img  rounded my-3"
              alt=""
            />
          </div>
          {details}
        </Modal.Body>
        <Modal.Footer className="d-flex grey lighten-3 py-0">
          <div className="font-italic  small mx-auto">
            Need more information about this project? don't hesitate to contact
            me, I'll be happy to connect with you...
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Project;
