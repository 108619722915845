import React from "react";

const Name = (props) => {
  setInterval(() => {
    let a = document.getElementById("a");
    let o = document.getElementById("o");
    let m = document.getElementById("m");
    a.innerHTML = "A";
    setTimeout(() => {
        m.innerHTML = "M";
        setTimeout(() => {
            o.innerHTML = "O";
            setTimeout(() => {
              a.innerHTML = "";
              m.innerHTML = "";
              o.innerHTML = "";
            }, 1000);
        }, 500);
    }, 500);
    
  }, 2500);
  return (
    <h1 className="m-5 p-5 font-weight-bold red-text  rounded hv-100  text-center">
      <div className="m-5 p-5"></div>
      <div className="w-auto border border-danger rounded shadow shake p-3">
        <span className="shadow rounded-circle p-2 m-1" id="a"></span>
        <span className="shadow rounded-circle p-2 m-1" id="m"></span>
        <span className="shadow rounded-circle p-2 m-1" id="o"></span>
      </div>
    </h1>
  );
};

export { Name };
