import React from "react";
import { FaTwitter, FaLinkedinIn, FaFacebook } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { Button } from "react-bootstrap";

const LinkedIn = (props) => {
  return (
    <a
      href="https://www.linkedin.com/in/madibaamohelang"
      target="_blank"
      rel="noopener noreferrer"
      className="text-dark"
    >
      <Button bsPrefix="btn btn-sm btn-outline-primary rounded font-weight-bold py-1 px-2">
        <FaLinkedinIn /> LinkeIn
      </Button>
    </a>
  );
};
const Email = (props) => {
  return (
    <a href="mailto:hello@amomad.com" className="text-dark">
      <Button bsPrefix="btn btn-sm btn-outline-dark rounded font-weight-bold py-1 px-2">
        <FiMail /> Email
      </Button>
    </a>
  );
};
const Twitter = (props) => {
  return (
    <a
      href="https://twitter.com/Lawrence_madiba?s=09"
      target="_blank"
      rel="noopener noreferrer"
      className="text-dark"
    >
      <Button bsPrefix="btn btn-sm btn-outline-info rounded font-weight-bold py-1 px-2">
        <FaTwitter /> Twitter
      </Button>
    </a>
  );
};
const FaceBook = (props) => {
  return (
    <a
      href="https://facebook.com/amotech.sa"
      target="_blank"
      rel="noopener noreferrer"
      className="text-dark"
    >
      <Button bsPrefix="btn btn-sm btn-outline-primary rounded font-weight-bold py-1 px-2">
        <FaFacebook /> FaceBook
      </Button>
    </a>
  );
};

export { LinkedIn, Email, Twitter, FaceBook };
