import React, { Component } from "react";
import Index from "../components/home/home";

import NavBar from "../components/nav/nav";
import { ProtectedRoute, AuthRoute } from "../router/route";
import Login from "../components/auth/login";
import Register from "../components/auth/register";
import Footer from "../components/footer/footer";
import Dashboard from "../components/admin/dashboard";
import Profile from "../components/admin/profile";
import TermsAndConditions from "../components/footer/terms_and_conditions";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Name } from "../test/test";

class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { auth: false };
    this.authenticate = this.authenticate.bind(this);
  }
  authenticate(data) {
    localStorage.setItem("authenticated", data);
    this.setState({ auth: data });
    return data;
  }
  render() {
    const { auth } = this.state;
    return (
      <main>
        <div className="main-content-h">
          <Router>
            <NavBar auth={auth} />
            <Routes auth={auth} authenticate={this.authenticate} />
          </Router>
        </div>
        <Footer />
      </main>
    );
  }
}
export default MainLayout;

const Routes = (props) => {
  return (
    <main>
      <Switch>
        <Route exact path="/">
          <Index auth={props.auth} authenticate={props.authenticate} />
        </Route>
        <Route path="/test">
          <Name />
        </Route>
        <AuthRoute auth={props.auth} path="/login">
          <Login auth={props.auth} />
        </AuthRoute>
        <AuthRoute auth={props.auth} path="/register">
          <Register auth={props.auth} />
        </AuthRoute>

        <ProtectedRoute auth={props.auth} path="/admin">
          <Dashboard auth={props.auth} />
        </ProtectedRoute>
        <ProtectedRoute auth={props.auth} path="/admin/profile">
          <Profile auth={props.auth} />
        </ProtectedRoute>

        <Route exact path="/terms_and_conditions">
          <TermsAndConditions />
        </Route>
      </Switch>
    </main>
  );
};
