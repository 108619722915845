import React, { Component } from "react";

class Profile extends Component {
  // props.auth
  render() {
    let msg = "";
    this.props.auth ? (msg = "loged in") : (msg = "loged out");
    return (
      <main className="border grey lighten-4 mt-5 pt-5 ">
        <h1 className="text-success mt-5"> This is your Profile [{msg}]</h1>
      </main>
    );
  }
}

export default Profile;
