import React from "react";
import { Navbar, Container } from "react-bootstrap";
import { Nav } from "react-bootstrap";

const NavBar = (props) => {
/*  let auth = false
  let admin_btn = "";
  let login_btn = (
    <Nav.Link className="nav-link black-text" href="/login">
      Login
    </Nav.Link>
  );
  let register_btn = (
    <Nav.Link className="nav-link black-text " href="/register">
      Register
    </Nav.Link>
  );

  if (auth) {
    admin_btn = (
      <Nav.Link className="nav-link black-text" href="/admin">
        Admin
      </Nav.Link>
    );
  }
*/
  return (
    <main>
      <Navbar className="shadow-none grey lighten-4" fixed="top" expand="lg">
        <Container>
          <Navbar.Brand className="navbar-brand ml-5 pl-5" href="/">
            AmoMad
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto mr-5">
              <Nav.Link className="nav-link black-text " href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link black-text" href="#projects">
                Projects
              </Nav.Link>
              <Nav.Link className="nav-link black-text" href="">
                Blog
              </Nav.Link>
              <Nav.Link className="nav-link black-text" href="#contactme">
                Contact
              </Nav.Link>

              {/* {admin_btn}
              {login_btn}
              {register_btn} */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </main>
  );
};

export default NavBar;
